vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Modelado corporal con "Muscle sculp"</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="muscle sculp" src="../../assets/musclesculp-home.svg" />
            </div>
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="muscle sculpting" src="../../assets/muscle-sculp-dentro.svg" />
            </div>
            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            El muscle sculpting es una técnica revolucionaria en el campo de la estética que permite definir
                            y tonificar los músculos de forma no invasiva. Con este tratamiento, se logra esculpir el cuerpo
                            y resaltar los músculos, creando una apariencia atlética y estilizada.
                        </p>

                        <p>
                            <span class="p-text-bold">¿Cómo funciona el muscle sculpting?</span><br>
                            El muscle sculpting utiliza tecnología avanzada para estimular los músculos de manera precisa.
                            Se aplican pulsos electromagnéticos focalizados en las áreas deseadas, lo que provoca
                            contracciones musculares intensas y supramáximas. Estas contracciones activan un proceso de
                            remodelación muscular, incrementando la fuerza y el tono muscular, y eliminando la grasa
                            localizada alrededor de los músculos.
                        </p>

                        <p>
                            <span class="p-text-bold">Beneficios del muscle sculpting</span><br>
                            - Definición y tonificación muscular.<br>
                            - Aumento de la fuerza y resistencia.<br>
                            - Reducción de la grasa localizada.<br>
                            - Mejora de la apariencia física y la confianza en uno mismo.<br>
                            - Resultados visibles y duraderos.
                        </p>

                        <p>
                            <span class="p-text-bold">Duración y resultados del muscle sculpting</span><br>
                            Cada sesión de muscle sculpting tiene una duración de aproximadamente 30 minutos. Se recomienda
                            un protocolo de varias sesiones para obtener resultados óptimos, generalmente entre 4 y 6
                            sesiones, dependiendo de las necesidades de cada cliente. Los resultados son visibles desde las
                            primeras sesiones y continúan mejorando con el tiempo, alcanzando su máximo potencial a las
                            pocas semanas de finalizar el tratamiento.
                        </p>

                        <p>
                            No pierdas la oportunidad de transformar tu cuerpo y lograr la definición muscular que siempre
                            has deseado. ¡Contáctanos hoy mismo para programar una cita y descubrir cómo el muscle sculpting
                            puede ayudarte a alcanzar tus objetivos de fitness y estética!
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'ModeladoVue',

    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>